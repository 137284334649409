
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import {Filter} from "@/cms-services/filter";
@Component
export default class Home extends Vue {
  loaded:boolean = false;
  dataSource: any = new ListDataSource({
    className: "BookletConfiguration",
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter:JSON.stringify([
        new Filter("eventId", +this.$route.params.eventId)
      ])
    },
  });

  createItemModel:any = {
    eventId:+this.$route.params.eventId,
    caption:""
  }

  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Конфигурации буклетов",
        disabled: true,
      },
    ];
  }

  async created() {
    await this.eventObjectDataSource.get();
    this.loaded = true;
  }
}
